<template>
    <v-dialog
        v-model="dialog"
        max-width="290"
    >
        <v-card>
            <v-card-title class="headline text-center d-block">{{$t("modal.error_uploading_images.title")}}</v-card-title>

            <v-card-text>
                <div class="text-center">
                    <v-icon size="70" class="mb-2" color="primary">error</v-icon>
                    <p>{{$t("modal.error_uploading_images.description")}}</p>
                    <p><strong>Error: {{errorMessage}}</strong></p>
                    <v-btn color="primary" min-width="150" depressed rounded @click="onCloseModal">
                        {{$t("ok")}}
                    </v-btn>
                </div>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModalErrorUploadingImages',
    props: {
        errorMessage: {
            type: String
        }
    },
    data: () => ({
        dialog: true
    }),
    watch: {
        dialog: function(val) {
            if (!val) {
                this.onClose();
            }
        }
    },
    methods: {
        onCloseModal() {
            this.$emit('closeModalIsErrorImages');
        }
    }
};
</script>

<template>
    <v-dialog
        v-model="dialog"
        max-width="350"
        persistent
    >
        <v-card>
            <v-card-text>
                <div class="text-center pt-5">
                    <loading />
                    <p>{{ $t("survey.modal.image_upload.title", { processedCount, imageCount }) }}</p>
                    <p>{{ updatedProgress }}%</p>
                    <template v-if="updatedProgress === 0">
                        <p>{{ $t("survey.modal.image_upload.progress_0") }}</p>
                    </template>
                    <template v-if="updatedProgress > 0 && updatedProgress !== 100">
                        <p class="pt-2">{{ $t("survey.modal.image_upload.progress>0") }}<br />
                            <strong>{{ $t("survey.modal.image_upload.keep_awake") }}</strong></p>
                    </template>
                    <template v-if="updatedProgress === 100">
                        <p class="pt-2">{{ $t("survey.modal.image_upload.progress=100") }}<br />
                            <strong>{{ $t("survey.modal.image_upload.keep_awake") }}</strong></p>
                    </template>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { submitAllFilesToSurvey } from "../../../graphql/mutations/surveys";
import { uploadFileToCloudinary } from "../../../helpers/helpers";
import { mapState } from "vuex";
function tryAtMost(tries, executor) {
    --tries;
    return new Promise(executor)
        .catch(err => tries > 0 ? tryAtMost(tries, executor) : Promise.reject(err));
}
export default {
    name: "ModalIsUploadingImages",
    data: () => ({
        dialog: true,
        updatedProgress: 0,
        imageCount: 0,
        processedCount: 0
    }),
    computed: {
        ...mapState("surveys", ["currentSurvey", "filesToQuestions", "tempSurveyId"])
    },
    methods: {
        submitFileToSurvey(payload) {
            return this.$apollo.mutate({
                mutation: submitAllFilesToSurvey,
                variables: {
                    instanceId: payload.instanceId,
                    answerId: payload.answerId,
                    fileName: payload.fileName,
                    fileType: payload.fileType,
                    optionId: payload.optionId || null
                }
            });
        },
        uploadFile(file, payload) {
            return new Promise((resolve, reject) => {
                const typeOfFile = payload.fileType;
                let fileType = "image";

                if (typeOfFile) {
                    if (typeOfFile.includes("video")) {
                        fileType = "video";
                    } else if (typeOfFile.includes("pdf")) {
                        fileType = "pdf";
                    } else {
                        fileType = 'image';
                    }
                }

                uploadFileToCloudinary(file, payload, 3).then((response) => {
                    const file = {
                        instanceId: payload.instanceId,
                        answerId: payload.answerId,
                        fileName: response.public_id,
                        fileType: fileType,
                        optionId: payload.optionId || null
                    };

                    const fileSubmitToCMS = this.submitFileToSurvey(file);
                    tryAtMost(3,(res,reject) => {
                        resolve(fileSubmitToCMS)
                    }).catch(err => {
                        reject(err);
                    }).then(result => resolve(result));
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        allProgress(promises, progressCallback) {
            let counter = 0;
            progressCallback(0);
            for (const promise of promises) {
                promise.then(() => {
                    counter++;
                    this.processedCount++;
                    progressCallback((counter * 100) / promises.length);
                });
            }
            return Promise.all(promises).then((result) => {
                // all asynchronous events are finished!
                // console.log("all are resolved in promise all", result);
                // this.$emit('allFilesUploaded');
                return result;
            }).catch((e) => {
                this.$emit("allFilesUploadedFailed", e);
            });
        },
        triggerUploadFiles() {
            const filesToQuestions = this.filesToQuestions;
            const allFiles = [];
            filesToQuestions.forEach((item, index, array) => {
                const files = item.images;
                const payload = {
                    instanceId: item.instanceId,
                    answerId: item.answerId,
                    path: item.path,
                    fileType: item.fileType,
                    optionId: item.optionId
                };

                files.forEach((file) => {
                    allFiles.push({
                        file: file,
                        payload: payload
                    });
                });

                // if last item, start upload
                if (index === array.length - 1) {
                    this.imageCount = allFiles.length;
                    this.allProgress(allFiles.map(async (file) => {
                            try {
                                return await this.uploadFile(file.file.fileObject, file.payload);
                            } catch (e) {
                                this.$emit("allFilesUploadedFailed", e);
                            }
                        }),
                        (p) => {
                            this.updatedProgress = parseInt(p.toFixed(0));
                            if (this.updatedProgress === 100) {
                                const delayCompletedMessage = 3000;
                                setTimeout(() => {
                                    this.$emit("allFilesUploaded");
                                }, delayCompletedMessage);
                            }
                        });
                }
            });
        },
        onCloseModal() {
            this.$store.dispatch("modals/closeModal");
        }
    },
    created() {
        this.triggerUploadFiles();
    }
};
</script>

<template>
    <v-dialog
        v-model="dialog"
        max-width="290"
        persistent
    >
        <v-card>
            <v-card-title class="headline text-center d-block">{{$t('survey.modal.completed.title')}}</v-card-title>

            <v-card-text>
                <div class="text-center">
                    <v-icon size="70" class="mb-2" color="primary">done_all</v-icon>
                    <p>{{$t('survey.modal.completed.body')}}</p>
                    <v-btn color="primary" min-width="150" outlined rounded @click="onSubmit">
                        {{$t('survey.modal.completed.see_all_completed')}}
                    </v-btn>
                </div>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModalSurveyCreated',
    data: () => ({
        dialog: true
    }),
    watch: {
        dialog(val) {
            this.onCloseModal();
        }
    },
    methods: {
        onSubmit() {
            this.dialog = false;
            this.$router.push('/completed');
        },
        onCloseModal() {
            this.$store.dispatch('modals/closeModal');
        }
    }
};
</script>
